import Header from '../components/Header';
import MainBanner from '../components/MainBanner';
import Services from '../components/Services';
import AboutUs from '../components/AboutUs';
import ContactSection from '../components/ContactSection';
import Reviews from '../components/Reviews';
import CompanyStats from '../components/CompanyStats';
import Footer from '../components/Footer';
import ProjectSection from '../components/ProjectsSection';
import GoTopButton from '../components/generic/GoTopButton';

export default function Home() {
  return (
    <div className="min-h-full">
        
        <Header />
        <main>
          <MainBanner />
          <AboutUs />
          <Services />
          <ProjectSection />
          <CompanyStats />
          <Reviews />
          <ContactSection />
          <Footer />
          <GoTopButton />
        </main>
      </div>
  );
}