import Header from '../components/Header';
import ContactUs from '../components/ContactUs';
import Footer from '../components/Footer';
import GoTopButton from '../components/generic/GoTopButton';

export default function Contact()  {
  return (
    <div className="min-h-full">
        
        <Header />
        <main>          
          <ContactUs />
          <Footer />
          <GoTopButton />
        </main>
      </div>
  );
}